<template>
    <div>
        <iq-card class="p-0">
          <template v-slot:body>
            <b-row>
              <b-col cols="12" sm="6" md="3">
                <input type="text" v-model="filters.search" placeholder="search by name or phone" class="form-control" @input="getTour">
              </b-col>
              <b-col cols="12" sm="6" md="3">
                <select class="form-control" v-model="filters.project_id"  @change="getTour">
                  <option selected disabled value="">select Project...</option>
                  <option v-for="(i, key) in projects" :key="key" :value="i.id">
                    <span>{{i.en_title}}</span>
                  </option>
                </select>
              </b-col>
              <b-col cols="12" sm="6" md="3">
                <v-select class="w-100" label="name" :options="allUsers" v-model="filters.sales_id" @option:selected="getTour" @search="onSearchCreatedBy"
                      :reduce="option => option.id"
                      placeholder="Choose User">
                    <template slot="no-options">
                      type to search about users...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                </v-select>
              </b-col>
              <b-col cols="12" sm="6" md="3">
                <v-select class="w-100" label="name" :options="allUsers" v-model="filters.trainer_id" @option:selected="getTour" @search="onSearchCreatedBy"
                      :reduce="option => option.id"
                      placeholder="Choose trainer">
                    <template slot="no-options">
                      type to search about trainer...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                </v-select>
              </b-col>
              <b-col cols="12">
                <date-range-picker
                    class="w-100 mt-2"
                    ref="picker"
                    opens=false
                    :timePicker=false
                    :timePicker24Hour=false
                    :showWeekNumbers=true
                    :showDropdowns=true
                    format='mm/dd/yyyy'
                    direction="center"
                    :autoApply=true
                    v-model="dateRange"
                    :linkedCalendars=true
                    @update="getTour"
                >
          </date-range-picker>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Tour History</h4>
            </template>
            <template v-slot:body>
                <b-table :busy="loadingTable" striped responsive :fields="columnTour" :items="allTour" class="mb-0 table-borderless">
                    <template v-slot:table-busy>
                      <div class="text-center">
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                      </div>
                    </template>
                    <template v-slot:cell(user)="data1">
                        <router-link :to="{name:'leadProfile', params:{id:data1.item.lead_id}}">{{data1.item.lead.name}}</router-link>
                    </template>
                    <template v-slot:cell(trainer)="data">
                      <p>{{data.item.trainer ? data.item.trainer.name : '---'}}</p>
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <p class="holdSelection">{{timeConvert(data.item.created_at)}}</p>
                    </template>
                    <template v-slot:cell(sales)="data">
                      <p>{{data.item.sales.name}}</p>
                    </template>
                    <template v-slot:cell(package_name)="data">
                      <div>
                        <p v-for="(item,index) in data.item.tour_package_name" :key="index">{{ item }}</p>
                      </div>
                    </template>
                  </b-table>
              <b-pagination v-model="pagination.currentPage"
              class="mt-3"
              :total-rows="pagination.total"
              align="right"
              :per-page="pagination.per_page"
              aria-controls="my-table"
              @input="getTour"
                ></b-pagination>
            </template>
          </iq-card>
    </div>
</template>
<script>
import tourServices from '@/modules/tour/services/tour'
import { mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  data () {
    return {
      allTour: [],
      projects: [],
      dateRange: {
        startDate: '',
        endDate: ''
      },
      filters: {
        search: '',
        project_id: '',
        sales_id: '',
        trainer_id: ''
      },
      allUsers: [],
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      loadingTable: false,
      columnTour: [
        { label: 'Project', key: 'project.en_title', class: 'text-center' },
        { label: 'User Name', key: 'user', class: 'text-left' },
        { label: 'Start Time', key: 'created_at', class: 'text-left' },
        { label: 'End Time', key: 'end_at', class: 'text-left' },
        { label: 'comment', key: 'comment', class: 'text-left' },
        { label: 'membership status', key: 'membership_status', class: 'text-left' },
        { label: 'Package Name', key: 'package_name', class: 'text-left' },
        { label: 'sales', key: 'sales', class: 'text-left' },
        { label: 'Trainer', key: 'trainer', class: 'text-left' },
        { label: 'Ended By', key: 'ended_by', class: 'text-left' }
      ]
    }
  },
  components: {
    DateRangePicker
  },
  methods: {
    onSearchCreatedBy (data, loading) {
      if (data.length) {
        loading(true)
        this.$store.dispatch('serachAboutUser', { user: data }).then(res => {
          this.allUsers = res.data.data
          loading(false)
        }).catch(() => {
          loading(false)
        })
      }
    },
    getTour () {
      this.loadingTable = true
      tourServices.gatTourHistoryReport({
        page: this.pagination.currentPage,
        ...this.filters,
        date_from: this.dateRange.startDate ? new Date(this.dateRange.startDate).toJSON().slice(0, 10) : '',
        date_to: this.dateRange.endDate ? new Date(this.dateRange.endDate).toJSON().slice(0, 10) : ''
      }).then(res => {
        this.allTour = res.data.data
        this.pagination.currentPage = res.data.meta.current_page
        this.pagination.per_page = res.data.meta.per_page
        this.pagination.total = res.data.meta.total
      }).finally(() => {
        this.loadingTable = false
      })
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  created () {
    this.$store.dispatch('getInfoToCreateLead').then((res) => {
      this.projects = res.data.data.projects
    })
    this.getTour()
  }
}
</script>
